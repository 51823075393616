<template>
  <div class="register">
    <div class="row-bg">
      <div style="display: flex;justify-content: center;">
        <img height="80" :src="logo" style="border-radius: 10px;" alt="" />
      </div>
      <h2 style="text-align: center;">{{ system }}</h2>
      <el-form ref="registerRef" :model="user" style="margin-top: 30px" label-width="100" :rules="rules">
        <el-row>
          <el-col :span="11">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="user.name" placeholder="姓名" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11" >
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="user.phone" placeholder="手机号" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
          <el-form-item label="验证码" prop="imgCode">
          <div style="display: flex; justify-content: center; height: 30px;">
            <el-input v-model="imgCode" autocomplete="off" style="height: 30px; margin-right: 20px;" @keyup.enter="submitForm"  placeholder="图片验证码" />
          </div>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <div v-html="codeImg" @click="getImgCode"></div>
      </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item label="短信验证码" prop="code">
            <el-input v-model="user.code" placeholder="验证码" />
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="1">
            <el-button type="success" :disabled="codeTxt.disabled" @click="getSMSCode">{{ codeTxt.txt }}</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11" >
            <el-form-item label="年龄" prop="age">
              <el-input v-model.number="user.age" placeholder="年龄" />
            </el-form-item>
          </el-col>
          <el-col :span="11"  :offset="2">
            <el-form-item label="性别" prop="gender">
              <el-radio-group v-model="user.gender" prop="性别">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item label="身高(cm)" prop="height">
              <el-input v-model.number="user.height" placeholder="身高(cm)" />
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item label="体重(kg)" prop="weight">
              <el-input v-model.number="user.weight" placeholder="体重(kg)" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="地址" prop="habitation">
          <el-cascader v-model="habitation" :options="addressOptions" placeholder="地址" />
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input v-model="user.address" placeholder="详细地址" />
        </el-form-item>
        <!-- <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="user.password" placeholder="密码" />
        </el-form-item>
        <el-form-item label="确认密码" prop="confirm_password">
          <el-input type="password" v-model="user.confirm_password" placeholder="确认密码" />
        </el-form-item> -->
        <el-form-item label="邀请码" prop="invitation_code">
          <el-input v-model="user.invitation_code" placeholder="邀请码" />
        </el-form-item>
        <el-row justify="space-between" align="middle">
          <div style="font-size: 14px;cursor: pointer; color: #3aa20f;" @click="login">已有账号? 登录</div>
          <el-button type="primary" @click="register(registerRef)">注册</el-button>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import axios from '@/libs/axios'
import { ref, reactive, onMounted } from 'vue'
import conf from '@/conf'
import { data as addressOptions } from '@/libs/provinces.json'
import router from '@/router'
import { ElMessage } from 'element-plus'

const habitation = ref('')
const registerRef = ref(null)
const user = reactive({
  name: '',
  phone: '',
  gender: 1,
  age: '',
  height: '',
  weight: '',
  habitation: '',
  address: '',
  invitation_code: '',
  code: ''
})
const system = conf.system
const logo = conf.logo
const imgCode = ref('')
const codeImg = ref('')
const rules = {
  name: [
    { required: true, message: '此项必填', trigger: 'blur' }
  ],
  phone: [
    { required: true, message: '此项必填', trigger: 'blur' },
    { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur' }
  ],
  age: [
    { required: true, message: '此项必填', trigger: 'blur' },
    { type: 'number', message: '只能为数字', trigger: 'blur' }
  ],
  height: [
    { type: 'number', message: '只能为数字', trigger: 'blur' }
  ],
  weight: [
    { type: 'number', message: '只能为数字', trigger: 'blur' }
  ],
  code: [
    { required: true, message: '此项必填', trigger: 'blur' }
  ]
  // password: [
  //   { required: true, message: '此项必填', trigger: 'blur' }
  // ],
  // confirm_password: [
  //   { required: true, message: '此项必填', trigger: 'blur' }
  // ]
}

const codeTxt = reactive({
  txt: '获取验证码',
  disabled: false,
  time: 120
})

const getSMSCode = async () => {
  if (!user.phone) {
    ElMessage.error('手机号不能为空')
    return
  }
  if (!imgCode.value) {
    ElMessage.error('验证码不能为空')
    return
  }
  try {
    const res = await axios.post('/users/getSMSCodes', { phone: user.phone, imgCode: imgCode.value })
    codeTxt.disabled = true
    const countdownInterval = setInterval(() => {
    codeTxt.txt = `${codeTxt.time}S后可获取`
    codeTxt.time--
      if (codeTxt.time < 0) {
        codeTxt.txt = '获取验证码'
        clearInterval(countdownInterval)
        codeTxt.disabled = false
        codeTxt.time = 120
      }
    }, 1000)
  } catch (err) {
    console.log('err :>> ', err)
  }
}

const login = () => router.push({ name: 'Login' })

const validate = (formRef, callback) => {
  formRef.validate(valid => {
    if (valid) callback()
  })
}

const register = formRef => {
  validate(formRef, async () => {
    user.habitation = habitation.value[0] + '-' + habitation.value[1] + '-' + habitation.value[2]
    const response = await axios.post('/users/register', { ...user })
    console.log('res', response)
    formRef.resetFields()
  })
}

// 获取图片验证码
const getImgCode = async () => {
  const response = await axios.get('/users/getImgCode')
  // codeImg.value = window.URL.createObjectURL(response)
  codeImg.value = response
  console.log('response :>> ', response)
}

onMounted(() => {
  getImgCode()
})
</script>

<style scoped>
.register {
  /* background-color: #ccc; */
  background: url('../../public/20230725120532.jpg') no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.error {
  color: red;
}

.row-bg {
  /* margin: 0px auto; */
  margin-right: 100px;
  height: 650px;
  width: 500px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px #ccc;
  padding: 40px;
  background-color: #ffffff;
}
</style>
